export class URLHelper {
    public static addHttpToUrl(url: string): string {
      return (url.indexOf('http://') >= 0 || url.indexOf('https://') >= 0) ? url : 'http://' + url;
    }
  
    public static getQueryParams(url: string): any {
      if (url.indexOf('?') > -1) {
        const queryString = url.split('?')[1];
        if (queryString.indexOf('=') > -1) {
          const queryArray = queryString.split('&');
          const params: any = {};
          queryArray.forEach(query => {
            const qArr: string[] = query.split('=');
            if (qArr.length >= 2) {
                params[qArr[0]] = qArr[1];
            }
          });
          return params;
        }
      }
      return {};
    }
  }
  